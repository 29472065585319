<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }"></div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'LoginLink',
  data: function () {
      return {
          code: '',
          email: '',
          password: '',
          password_2: '',
          image: '',
          user: false
      }
  },
  mounted: async function() {
    this.code = await this.$route.params.code;

    let params = {
      code: this.code
    };

    let response = await login.forceLogin(params);

    if(response.type == "success"){

      let data = response.data;

      localStorage.setItem("domain", "demo");
      localStorage.setItem("token", data.api_token);
      localStorage.setItem("role", data.role);

      axios.defaults.headers.common['domain'] = "demo";
      axios.defaults.headers.common['api-token'] = data.api_token;
      
      if(response.data.role == "administrator"){
        window.location.href = "/#/home"; return;
      }
      else{
        if(response.data.role == "supplier"){
          window.location.href = "/#/welcome"; return;
        }
        else{
          if(response.data.role == "manager"){
            window.location.href = "/#/welcome"; return;
          }
          else{
            window.location.href = "/#/404"; return;
          } 
        } 
      }
    }
  },
  methods: {
    login: async function(){
      if(this.email == ""){
        alert("El correo electrónico es obligatorio.");
        return;
      }

      if(this.password == ""){
        alert("La contraseña es obligatoria.");
        return;
      }

      if(this.password != this.password_2){
        alert("Las contraseñas no coinciden.");
        return;
      }

      let params = {
        code: this.code,
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.loginByCode(params);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("domain", "demo");
        localStorage.setItem("token", data.api_token);
        localStorage.setItem("role", data.role);

        axios.defaults.headers.common['domain'] = "demo";
        axios.defaults.headers.common['api-token'] = data.api_token;
        
        if(response.data.role == "administrator"){
          window.location.href = "/#/home"; return;
        }
        else{
          if(response.data.role == "supplier"){
            window.location.href = "/#/welcome"; return;
          }
          else{
            if(response.data.role == "manager"){
              window.location.href = "/#/welcome"; return;
            }
            else{
              window.location.href = "/#/404"; return;
            } 
          } 
        }
      }
      else{
        alert(response.message);
      }
    },
  }
}
</script>
